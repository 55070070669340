import React, { useState } from 'react'
import './styles.css'
import { TextField } from '@material-ui/core'
import { tryLogin } from '../../services'
import tienoo_logo from '../Site/tienoo_red.png'

export default (props) => {
  const [ username, setUsername ] = useState('')
  const [ password, setPassword ] = useState('')

  return (
    <div className="Login">
      <div style={{ width: 200, position: 'absolute', top: 0, left: 0, zIndex: 200 }}>
        <div className="main-logo">
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
            src={tienoo_logo} />
        </div>
      </div>
      <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', marginTop: 100 }}>
        {/* <div style={{ padding: 15, color: 'rgba(0,0,0,0.8)', fontSize: 16 }}>
          Login
        </div> */}
        <div>
          <TextField
            id="username-input"
            tabIndex={2}
            color="primary"
            variant="outlined"
            placeholder="Username"
            value={ username }
            onChange={
              (e) => {
                setUsername(e.target.value)
              }
            }
          />
        </div>
        <div style={{ marginTop: 5 }}>
          <TextField
            id="password-input"
            tabIndex={2}
            color="primary"
            variant="outlined"
            placeholder="Password"
            value={ password }
            onChange={
              (e) => {
                setPassword(e.target.value)
              }
            }
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <button
            className="login-btn"
            tabIndex={0}
            onClick={
              (e) => {
                tryLogin(username, password).then(
                  success => {
                    if (success) {
                      window.location.reload()
                    }
                  }
                )
              }
            }>
            Login
          </button>
        </div>
      </div>
    </div>
  )
}
