import React, { useState, useEffect } from 'react'
import './styles.css'
import { makeObjectView, LoadingIndicator } from '../_generics'
import CustomModal from '../_generics/CustomModal'
import { getHosts, putHosts, getEnabledViews, getBaseSite_v2_byName, putSite_v3_byName } from '../../services'
import { randomNumUnder, getUsername } from '../../utils'
import { path as rpath, map, filter, includes, prepend } from 'ramda'
import { FaArrowRight, FaUser } from "react-icons/fa"
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai"
import { IconContext } from "react-icons"
import tienoo_logo from './tienoo_red.png'
import { logout } from '../../services';
import { FiUploadCloud, FiSave, FiRepeat, FiFlag } from "react-icons/fi"
import { MdAddBox } from "react-icons/md"

const saveHosts = (hosts, originalHosts) => {
  putHosts(hosts).then(
    res => alert(res)
  )
  const hostNames = map(_ => _.username, hosts)
  const originalHostNames = map(_ => _.username, originalHosts)
  const newHosts = hostNames.filter(name => !includes(name, originalHostNames))

  getBaseSite_v2_byName('tienoo').then(default_site => {
    newHosts.forEach(_ => {
      putSite_v3_byName(default_site, _)
    })
  })
}

const addHost = (setHosts, Hosts) => {
  setHosts(
    prepend(
      {
        id: randomNumUnder(100000000),
        username: 'tienoo/',
        password: '',
        longitude: '',
        latitude: '',
        sound: '',
        enabledServices: ['Site']
      },
      Hosts
    )
  )
}

export default ({ setView, currentView, views }) => {
  const [ Hosts, setHosts ] = useState([])
  const [ originalHosts, setOriginalHosts ] = useState([])
  const [ fetched, setFetched ] = useState(false)
  const [ showAccountMenu, setShowAccountMenu ] = useState(false)

  const enabledViews = getEnabledViews()

  useEffect(() => {
    if (!fetched) {
      getHosts().then(
        storedHosts => {
          setHosts(storedHosts)
          setOriginalHosts(storedHosts)
          setFetched(true)
        }
      )
    }
  }, [])

  if (!fetched) return (
    <CustomModal>
      <div className="loading-container">
        <LoadingIndicator />
        <div className="loading-description">
          Loading hosts...
        </div>
      </div>
    </CustomModal>
  )
  return (
    <div className="Hosts">
      <div className="Actions">
        <div className="main-logo">
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
            src={tienoo_logo} />
        </div>

        <div className="language-btn">
          <div
            className="action-btn hover-opacity"
            onClick={() => {
              setShowAccountMenu(!showAccountMenu)
            }}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FaUser />
            </IconContext.Provider>
            Account
            { showAccountMenu ? 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretUp />
            </IconContext.Provider> : 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretDown />
            </IconContext.Provider>
            }
          </div>
        </div>

        { showAccountMenu &&
        <div className="a">
          { map(
            view => (
              <div
                key={view.name}
                className={"action-btn hover-opacity drawer-item" + ` ${view.name}-btn`}
                onClick={() => setView(view)}>
                { view.name }
              </div>
            ),
            filter(
              v => includes(v.name, enabledViews) && v.name !== 'Hosts',
              views
            )
          ) }
          <div
            className={"action-btn hover-opacity drawer-item" + ' logout-btn'}
            onClick={() => { logout(); window.location.reload() }}>
            Logout
          </div>
        </div> }

        <div
          className="save-btn hover-opacity"
          onClick={() => addHost(setHosts, Hosts)}>
          <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
            <MdAddBox />
          </IconContext.Provider>
          Add Host
        </div>
        <div
          className="save-btn hover-opacity"
          onClick={() => saveHosts(Hosts, originalHosts)}>
          <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
            <FiSave />
          </IconContext.Provider>
          Save
        </div>

      </div>
      <div className="Host-items">
        { makeObjectView(setHosts)(Hosts) }
      </div>
    </div>
  )
}
