import React from 'react'
import {
  prop,
  assoc,
  curry,
  addIndex,
  map,
  replace,
  toUpper,
  reject,
  any,
  startsWith,
  keys,
  dissoc,
  mergeLeft,
  compose,
  path,
  uniq,
  flatten,
  toPairs,
  filter,
  mergeAll,
  set,
  lensPath,
  mapObjIndexed,
  append
} from 'ramda'

export const element = curry(React.createElement)
export const randomNumUnder = (max) => Math.floor(Math.random()*max)
export const mapIndexed = addIndex(map)
export const capitalize = replace(/^./, toUpper);
export const getUsername = () => JSON.parse(
  window.localStorage.getItem('user') || '{}'
).username
export const getPassword = () => JSON.parse(
  window.localStorage.getItem('user') || '{}'
).password
export const getEnabledServices = () => JSON.parse(
  window.localStorage.getItem('user') || '{}'
).enabledServices
export const getLeafs = pages => reject(
  _ => any(n => startsWith(_, n))(keys(dissoc(_, pages))),
  keys(pages)
)
export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}
export const renameKey = curry((oldKey, newKey, obj) =>
  assoc(newKey, prop(oldKey, obj), dissoc(oldKey, obj)))

export const mergeMissingLanguages = (origin, target) => {
  const pageNames = Object.keys(target)
  let newSite = Object.assign({}, target)

  for (const pageName of pageNames) {
    newSite[pageName] = mergeLeft( origin[pageName], target[pageName] )
  }

  return newSite
}

const mapLinks = map(prop('linkTo'))

const getButtonTargets = a => {
  console.log('aaa', a)
  return compose(
    map(
      compose(
        mapLinks,
        path(['buttons'])
      )
    )
  )(a)
}

const getPagesChildren = site => pagesList => compose(
  filter(_ => _ !== ""),
  uniq,
  flatten,
  (pages) => (!pages || pages.length === 0) ? pagesList : [ pagesList, ...map(page => !site[page] ? '' : getPagesChildren(site)(pages)(site[page]), pages ) ],
  (_) => { console.log('ccc', _); return _ },
  filter(_ => _ !== ""),
  uniq,
  flatten,
  map(pair => pair[1]),
  toPairs,
  getButtonTargets
)

export const copySingleLangPage = (pageContent, site) => {
  if (!pageContent || !site) return null

  console.log('single', pageContent)
  window.localStorage.setItem('copiedSingleLangPage', JSON.stringify(pageContent))
  
  alert(`Copied a single language of a page.`)
}

export const copyPage = (pageContent, site) => {
  if (!pageContent || !site) return null

  console.log('single', pageContent)
  window.localStorage.setItem('copiedSinglePage', JSON.stringify(pageContent))
  
  alert(`Copied a single page.`)
}

export const copyPages = (origin, site) => {
  if (!origin || !site) return null

  const rootPage = site[origin]
  const getChildrenNames = getPagesChildren(site)([ origin ])(rootPage)
  const namesToObjects = mergeAll(map(
    _ => site[_] ? ({
      [_]: site[_]
    }) : {},
    getChildrenNames
  ))

  console.log(getChildrenNames, namesToObjects)
  window.localStorage.setItem('copiedPages', JSON.stringify(namesToObjects))
  
  alert(`Copied ${getChildrenNames.length} pages.`)
}

export const pastePages = (site, setSite) => {
  const copiedPagesJSON = window.localStorage.getItem('copiedPages')

  if (!copiedPagesJSON) {
    alert('No copied hierarcy foumd. Use the "COPY HIERARCY" button in pages.')
  }

  const copiedPages = JSON.parse(copiedPagesJSON)
  if (window.confirm(`Sure you want to paste the following pages. Any page with a same name will be overwritten. Pages:
${ Object.keys(copiedPages) }`)) {
    setSite(
      mergeAll([ site, copiedPages ])
    )
  }

}

export const pastePage = (targetPageName, site, setSite) => {
  const copiedPageJSON = window.localStorage.getItem('copiedSinglePage')

  if (!copiedPageJSON) {
    alert('No copied single page foumd. Use the "COPY ALL LANGS" button in pages.')
  }

  const copiedPage = JSON.parse(copiedPageJSON)
  if (window.confirm(`Sure you want to paste and override content on this page.`)) {
    setSite(
      set(lensPath([ targetPageName ]), copiedPage, site)
    )
  }

}

export const pasteSingleLangPage = (targetPageName, site, setSite, currentLanguage) => {
  const copiedPageJSON = window.localStorage.getItem('copiedSingleLangPage')

  if (!copiedPageJSON) {
    alert('No copied single page foumd. Use the "COPY SINGLE LANG" button in pages.')
  }

  const copiedPage = JSON.parse(copiedPageJSON)
  if (window.confirm(`Sure you want to paste and override content on this page in current language.`)) {
    setSite(
      set(lensPath([ targetPageName, currentLanguage ]), copiedPage, site)
    )
  }

}

export const copyFullLanguaguge = (site, currentLanguage) => {
  const siteWithOnlyOneLang = map(
    page => ({ [currentLanguage]: page[currentLanguage] }),
    site
  )
  localStorage.setItem(
    'copiedFullSingleLanguage',
    JSON.stringify(siteWithOnlyOneLang)
  )
  localStorage.setItem(
    'copiedFullSingleLanguageName',
    JSON.stringify(currentLanguage)
  )
  alert('Copied full language: ' + currentLanguage)
}

export const pasteFullSingleLanguage = (site, setSite, languages, setLanguages) => {
  const singleLangSite = JSON.parse(localStorage.getItem('copiedFullSingleLanguage') || '""')
  const singleLangSiteName = JSON.parse(localStorage.getItem('copiedFullSingleLanguageName') || '""')
  if (singleLangSite) {
    const newSite = mapObjIndexed(
      (oldPage, key, fullSite) => ({ ...oldPage, ...singleLangSite[key] }),
      site
    )
    setSite(newSite)
    setLanguages(append(singleLangSiteName, languages))
    alert("Pasted a full language. If the language is new, please add it to as button to /CITY so it will show up in languages dropdown on tienoo.fi")
  } else {
    alert("Couldn't find any copied language. Try pressing Site -> Copy full single language")
  }
}