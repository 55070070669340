import { putAsset } from "./assets";

const API_KEY = "AIzaSyB6fZV9d7cOPGp5Oav8d6DEZHvNGcPIyvQ"

export const translate = (text, fromLang, toLang) => fetch(
  `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&q=${encodeURI(text)}&source=${fromLang || 'fi'}&target=${toLang || 'en'}`,
  { 
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    }
  }
)
.then(res => res.json())
.then((response) => {
  console.log("response from google: ", response.data.translations[0].translatedText);
  return response.data.translations[0].translatedText
})
.catch(error => {
  console.log("There was an error with the translation request: ", error);
  return 'Error'
});

export const textToSpeech = (text, lang, voice) => fetch(
  `https://texttospeech.googleapis.com/v1beta1/text:synthesize`,
  { 
    method: 'POST',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "X-Goog-Api-Key": "AIzaSyB6fZV9d7cOPGp5Oav8d6DEZHvNGcPIyvQ"
    },
    body: JSON.stringify({
      'input':{
        'text':`${text}`
      },
      'voice':{
        'languageCode': lang || 'en-gb',
        'name': voice || 'en-GB-Standard-A',
        'ssmlGender':'FEMALE'
      },
      'audioConfig':{
        'audioEncoding':'MP3'
      }
    })
  }
)
.then(res => res.json())
.then((response) => {
  return response.audioContent
})
.catch(error => {
  console.log("There was an error with the request: ", error);
  return 'Error'
})

/*
Curl -H "X-Goog-Api-Key: AIzaSyB6fZV9d7cOPGp5Oav8d6DEZHvNGcPIyvQ" \
  -H "Content-Type: application/json; charset=utf-8" \
  --data "{
    'input':{
      'text':'Android is a mobile operating system developed by Google,
         based on the Linux kernel and designed primarily for
         touchscreen mobile devices such as smartphones and tablets.'
    },
    'voice':{
      'languageCode':'en-gb',
      'name':'en-GB-Standard-A',
      'ssmlGender':'FEMALE'
    },
    'audioConfig':{
      'audioEncoding':'MP3'
    }
  }" "https://texttospeech.googleapis.com/v1beta1/text:synthesize" > synthesize-text.txt
  */