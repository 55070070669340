import { map, mergeRight, last, split, prepend, keys, append } from 'ramda'
import axios from 'axios'
import { getUsername, getPassword, mergeMissingLanguages, b64toBlob } from '../utils'
import { translate, textToSpeech } from './google.js'
import { putAsset } from './assets'

var AWS = require("aws-sdk")
var s3_sites_access = new AWS.S3({
  region: 'eu-west-1',
  accessKeyId: 'AKIA4TWP6HG3V66NFZ74',
  secretAccessKey: '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'
})

const BASE_URL = 'https://admin.tienoo.fi' /*'https://admin.tienoo.fi.s3-eu-west-1.amazonaws.com'*/ // 'https://d2imh53w8skktn.cloudfront.net'

const setLocalUser = (user) => {
  window.localStorage.setItem(
    'user',
    JSON.stringify(user)
  )
}

export const tryLogin = (username, password) => {
  return login({
    username: username,
    password: password
  }).then(
    res => {
      if (res && res.loginSuccess) {
        setLocalUser({
          username,
          password,
          enabledServices: res.enabledServices
        })
        return true
      } else {
        window.alert('Wrong username or password.')
        setLocalUser({})
        return false
      }
    }
  )
}

export const login = (credentials) => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify(credentials)
  },
  data: {
    action: {
      type: 'login'
    }
  }
}).then(res => {
  console.log(res)
  return res.data
}).catch(e => {
  console.log(e)
  return null
})

export const logout = () => {
  setLocalUser({})
}

export const getHosts = params => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'getHosts'
    }
  }
}).then(res => {
  console.log(res)
  return res.data
}).catch(e => {
  console.log(e)
  return []
})

export const putHosts = hosts => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'putHosts',
      hosts: hosts
    }
  }
}).then(res => {
  console.log(res)
  return 'Success saving.'
}).catch(e => {
  console.log(e)
  return 'Error saving. Try removing special characters (€,∞,#..)'
})

export const getSiteStructure = params => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'getSiteStructure'
    }
  }
}).then(res => {
  console.log(res)
  return res.data
}).catch(e => {
  console.log(e)
  return {}
})

export const getSiteContent = params => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'getSiteContent'
    }
  }
}).then(res => {
  console.log(res)
  return res.data
}).catch(e => {
  console.log(e)
  return {}
})

export const putSite = site => axios({
  method: 'post',
  url: BASE_URL + '/adminActions',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'putSite',
      site: site
    }
  }
}).then(res => {
  console.log(res)
  return 'Success saving.'
}).catch(e => {
  console.log(e)
  return 'Error saving. Try removing special characters (€,∞,#..)'
})

export const getBaseSite_v2 = () => new Promise( (resolve, reject) => s3_sites_access.getObject(
  {
    Bucket: 'tienoo', 
    Key: getUsername() + '/site.json',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      const dataString = data.Body.toString()
      const response = dataString ? JSON.parse(dataString) : 'Couldt get site.'
      resolve(response)
    }
  }
)
)

export const putSite_v2 = site => new Promise( (resolve, reject) => s3_sites_access.putObject(
  {
    Body: JSON.stringify(site),
    Bucket: 'tienoo', 
    Key: getUsername() + '/site.json',
    // ACL: 'public-read',
    CacheControl: 'no-cache',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      resolve('Success saving.')
    }
  }
)
)

export const getBaseSite_v2_byName = (name) => new Promise( (resolve, reject) => s3_sites_access.getObject(
  {
    Bucket: 'tienoo', 
    Key: name + '/site.json',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      const dataString = data.Body.toString()
      const response = dataString ? JSON.parse(dataString) : 'Couldt get site.'
      resolve(response)
    }
  }
)
)

export const putSite_v2_byName = (site, name) => new Promise( (resolve, reject) => s3_sites_access.putObject(
  {
    Body: JSON.stringify(site),
    Bucket: 'tienoo', 
    Key: name + '/published-site.json',
    // ACL: 'public-read',
    CacheControl: 'no-cache',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      resolve('Success saving.')
    }
  }
)
)

export const putSite_v3_byName = (site, name) => new Promise( (resolve, reject) => s3_sites_access.putObject(
  {
    Body: JSON.stringify(site),
    Bucket: 'tienoo', 
    Key: name + '/site.json',
    // ACL: 'public-read',
    CacheControl: 'no-cache',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      resolve('Success saving.')
    }
  }
)
)

export const putSite_backup_v2 = site => new Promise( (resolve, reject) => s3_sites_access.putObject(
  {
    Body: JSON.stringify(site),
    Bucket: 'tienoo', 
    Key: getUsername() + '/__backup__' + new Date().toUTCString() + '/site.json',
    // ACL: 'public-read',
    CacheControl: 'no-cache',
  },
  function(err, data) {
    if (err) {
      console.log(err)
      resolve('Error saving. Try removing special characters (€,∞,#..)')
    } else {
      console.log(data)
      resolve('Success saving.')
    }
  }
)
)

export const publishSite = (hostName) => axios({
  method: 'post',
  url: BASE_URL + '/publish',
  headers: {
    credentials: JSON.stringify({
      username: getUsername(),
      password: getPassword()
    })
  },
  data: {
    action: {
      type: 'publish'
    },
    target: hostName
  }
}).then(res => {
  console.log(res)
  return 'Success publishing.'
}).catch(e => {
  console.log(e)
  return 'Errors while publishing, try again.'
})


// ->> FOR Updating data manually, remove later
/*

{
  axios({
    method: 'post',
    url: BASE_URL + '/adminActions',
    headers: {
      credentials: JSON.stringify({
        username: 'tienoo',
        password: 'Liop8cmeo'
      })
    },
    data: {
      action: {
        type: 'getSiteStructure'
      }
    }
  }).then(res => {
    console.log('WEW', res)

    axios({
      method: 'post',
      url: BASE_URL + '/adminActions',
      headers: {
        credentials: JSON.stringify({
          username: 'tienoo/Suomi',
          password: 'Kohdataan2'
        })
      },
      data: {
        action: {
          type: 'getSiteContent'
        }
      }
    }).then(res2 => {
      console.log('WEW2', res2)
      const rest = mergeMissingLanguages(res2.data, res.data)
      console.log('merged', rest)
      putSite_v2(rest)
      return res2.data
    }).catch(e => {
      console.log(e)
      return {}
    })

    return res.data
  }).catch(e => {
    console.log(e)
    return {}
  })
}

*/

export const getEnabledViews = () => prepend(
  'Login',
  JSON.parse(window.localStorage.getItem('user') || '{}').enabledServices
)

// (ms) => To get past googles limits / time
const forceLag = (ms) => new Promise((resolve, reject) =>  {
  setTimeout(
    () => resolve('done'),
    ms
  )
})

export const translateFullLanguageVersion = async (site, newLanguage, setSite, setLanguages, languages, translationOriginCode, translationTargetCode, textToSpeechLangCode, textToSpeechVoiceCode, setNewLanguage, setNewLangModalVisibility, setCreatingNewLanguage, origin = 'English') => {
  let siteCopy = JSON.parse(JSON.stringify(site))
  
  // COPY ENGLISH AS A BASE
  keys(site).forEach(page => {
    siteCopy[page][newLanguage] = JSON.parse(JSON.stringify(siteCopy[page][origin]))
  })

  // Translate texts
  await Promise.all(keys(siteCopy).map(async (page, index) => {
    
    if (page === '/CITY') {
      return siteCopy[page]
    }

    await forceLag(index * 400)

    // buttons
    siteCopy[page][newLanguage]['buttons'] = await Promise.all(
      siteCopy[page][newLanguage]['buttons'].map(async (button, index) => {
        await forceLag(index * 100)
        button['title'] = await translate(button['title'], translationOriginCode, translationTargetCode)
        return button
      })
    )

    await forceLag(index * 100)

    // maps
    siteCopy[page][newLanguage]['map'] = await Promise.all(
      siteCopy[page][newLanguage]['map'].map(async (map, index) => {
        await forceLag(index * 100)
        map['title'] = await translate(map['title'], translationOriginCode, translationTargetCode)
        return map
      })
    )

    await forceLag(index * 100)

    // pictures
    siteCopy[page][newLanguage]['picture'] = await Promise.all(
      siteCopy[page][newLanguage]['picture'].map(async (picture, index) => {
        await forceLag(index * 100)
        picture['title'] = await translate(picture['title'], translationOriginCode, translationTargetCode)
        return picture
      })
    )

    await forceLag(index * 100)

    // text
    siteCopy[page][newLanguage]['text'] = await Promise.all(
      siteCopy[page][newLanguage]['text'].map(async (text, index) => {
        await forceLag(index * 100)
        text['content'] = await translate(text['content'], translationOriginCode, translationTargetCode)
        return text
      })
    )

    await forceLag(index * 100)

    // videos
    siteCopy[page][newLanguage]['video'] = await Promise.all(
      siteCopy[page][newLanguage]['video'].map(async (video, index) => {
        await forceLag(index * 100)
        video['title'] = await translate(video['title'], translationOriginCode, translationTargetCode)
        return video
      })
    )

    return siteCopy[page]
  }))

  console.log('Translated texts,', siteCopy)

  
  // Texts to Speech
  await Promise.all(keys(siteCopy).map(async (page, index) => {

    if (page === '/CITY') {
      return siteCopy[page]
    }

    await forceLag(index * 400)
    
    // buttons
    siteCopy[page][newLanguage]['buttons'] = await Promise.all(
      siteCopy[page][newLanguage]['buttons'].map(async (button, index) => {
        await forceLag(index * 100)
        if (!button['title']) return button
        let translationResponse = await textToSpeech(button['title'], textToSpeechLangCode, textToSpeechVoiceCode)
        if (!translationResponse) return button
        button['sound'] = await new Promise((resolve, reject) => {
          putAsset(
            button['title'] + '-' + textToSpeechLangCode + '.mp3',
            b64toBlob(translationResponse, 'audio/mp3'),
            res => resolve(res)
          )
        })
        return button
      })
    )

    await forceLag(index * 100)

    // maps
    siteCopy[page][newLanguage]['map'] = await Promise.all(
      siteCopy[page][newLanguage]['map'].map(async (map, index) => {
        await forceLag(index * 100)
        if (!map['title']) return map
        let translationResponse = await textToSpeech(map['title'], textToSpeechLangCode, textToSpeechVoiceCode)
        if (!translationResponse) return map
        map['sound'] = await new Promise((resolve, reject) => {
          putAsset(
            map['title'] + '-' + textToSpeechLangCode + '.mp3',
            b64toBlob(translationResponse, 'audio/mp3'),
            res => resolve(res)
          )
        }) 
        return map
      })
    )

    await forceLag(index * 100)

    // pictures
    siteCopy[page][newLanguage]['picture'] = await Promise.all(
      siteCopy[page][newLanguage]['picture'].map(async (picture, index) => {
        await forceLag(index * 100)
        if (!picture['title']) return picture
        let translationResponse = await textToSpeech(picture['title'], textToSpeechLangCode, textToSpeechVoiceCode)
        if (!translationResponse) return picture
        picture['sound'] = await new Promise((resolve, reject) => {
          putAsset(
            picture['title'] + '-' + textToSpeechLangCode + '.mp3',
            b64toBlob(translationResponse, 'audio/mp3'),
            res => resolve(res)
          )
        }) 
        return picture
      })
    )

    await forceLag(index * 100)

    // text
    siteCopy[page][newLanguage]['text'] = await Promise.all(
      siteCopy[page][newLanguage]['text'].map(async (text, index) => {
        await forceLag(index * 100)
        if (!text['content']) return text
        let translationResponse = await textToSpeech(text['content'], textToSpeechLangCode, textToSpeechVoiceCode)
        if (!translationResponse) return text
        text['sound'] = await new Promise((resolve, reject) => {
          putAsset(
            text['content'] + '-' + textToSpeechLangCode + '.mp3',
            b64toBlob(translationResponse, 'audio/mp3'),
            res => resolve(res)
          )
        }) 
        return text
      })
    )

    await forceLag(index * 100)

    // videos
    siteCopy[page][newLanguage]['video'] = await Promise.all(
      siteCopy[page][newLanguage]['video'].map(async (video, index) => {
        await forceLag(index * 100)
        if (!video['title']) return video
        let translationResponse = await textToSpeech(video['title'], textToSpeechLangCode, textToSpeechVoiceCode)
        if (!translationResponse) return video
        video['sound'] = await new Promise((resolve, reject) => {
          putAsset(
            video['title'] + '-' + textToSpeechLangCode + '.mp3',
            b64toBlob(translationResponse, 'audio/mp3'),
            res => resolve(res)
          )
        }) 
        return video
      })
    )

    return siteCopy[page]
  })) 

  console.log('Completed Text-To-Speech,', siteCopy)


  setSite(siteCopy)
  setLanguages(append(newLanguage, languages))
  setNewLanguage('')
  setNewLangModalVisibility(false)
  setCreatingNewLanguage(false)
}