import React, { useState, useEffect } from 'react'
import './styles.css'
import Menu from '../Menu'
import { element, getUsername } from '../../utils'
import SaveIcon from '@material-ui/icons/Save'
import GridOnIcon from '@material-ui/icons/GridOn'
import FeedbackIcon from '@material-ui/icons/Feedback'
import AddIcon from '@material-ui/icons/Add'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import Lock from '@material-ui/icons/Lock'
import Home from '@material-ui/icons/Home'
import Poll from '@material-ui/icons/Poll'
import Hosts from '../Hosts'
import Login from '../Login'
import { tryLogin } from '../../services'
import { LoadingIndicator } from '../_generics'
import CustomModal from '../_generics/CustomModal'
import Site from '../Site'
import tienoo_logo from '../Hosts/tienoo_red.png'

/* // Imports the Google Cloud client library
const textToSpeech = require('@google-cloud/text-to-speech');

const client = new textToSpeech.TextToSpeechClient();
async function quickStart() {

  // The text to synthesize
  const text = 'hello, world!';

  // Construct the request
  const request = {
    input: {text: text},
    // Select the language and SSML voice gender (optional)
    voice: {languageCode: 'en-US', ssmlGender: 'NEUTRAL'},
    // select the type of audio encoding
    audioConfig: {audioEncoding: 'MP3'},
  };

  // Performs the text-to-speech request
  const [response] = await client.synthesizeSpeech(request);
  console.log('Received:', response)
}
quickStart() */

const DefaultView = () => (
  <div style={{ textAlign: 'center', paddingTop: '10%' }}>
    Logged in as: { getUsername() }
  </div>
)

const views = [
  !getUsername() ? {
    name: 'Login',
    component: Login
  } :
  /* {
    component: DefaultView,
    actions: []
  }, */
  {
    name: 'Site',
    component: Site
  },
  {
    name: 'Hosts',
    component: Hosts
  }
]

export default (props) => {
  const [ view, setView ] = useState(views[0])
  const [ fetched, setFetched ] = useState(false)

 /*  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user') || '{}')
    tryLogin(user.username, user.password).then(
      result => setFetched(true)
    ).catch(
      e => setFetched(true)
    )
  }, []) */

  return (
    <div className="App">
      {/* <Menu setView={setView} currentView={view} views={views} /> */}
      <div className="sidebar-bg" />
  {/*       <div className="main-logo">
          <img
            style={{
              height: '100%',
              width: 160,
              objectFit: 'contain'
            }}
            src={tienoo_logo} />
        </div>
      </div> */}
      
      
      { !fetched ? (
        <div className="Page">
          { element(view.component, { setView: setView, currentView: view, views: views }, null) }
        </div>
      ) : 
      <CustomModal>
        <LoadingIndicator /> 
      </CustomModal> }
      
      
    </div>
  )
}
