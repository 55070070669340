import React from 'react'
import {
    map,
    keys,
    prop,
    lensPath,
    set,
    remove,
    equals,
    head,
    split,
    compose,
    length,
    gt,
    both,
    includes,
    reject,
    append,
    startsWith
} from 'ramda'
import { mapIndexed, capitalize, getUsername, getEnabledServices } from '../../utils'
import './styles.css'
import {
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Switch from '@material-ui/core/Switch'

// Validations
const charLimits = {
  title: 200,
}

const beginsWithUsername = compose(
  equals(
    getUsername()
  ),
  head
)

const lengthOver1 = compose(
  _ => gt(_, 1),
  length
)

const userNameHasPrefix = compose(
  both(
    beginsWithUsername,
    lengthOver1
  ),
  split('/')
)

const BooleanRow = ({
  obj,
  property,
  objects,
  setter,
  parentIndex
}) => (
  <div
    key={ property + ':' + parentIndex }
    style={{ display: 'flex', flexDirection: 'row', marginBottom: 5, justifyContent: 'flex-start' }}>
    <div style={{ width: '35vw', paddingLeft: 0, paddingRight: 0, flexDirection: 'row', display: 'flex' }}>
      <div style={{ flex: 1, alignSelf: 'center', justifySelf: 'flex-start', textAlign: 'left', paddingRight: 20 }}>
        { property }
      </div>
      <div>
        <Switch
          checked={ prop(property, obj) }
          onChange={e => {
            setter(
              set(
                lensPath([parentIndex, property]),
                !(e.target.value == 'true'),
                objects
              )
            )
          }}
          value={ prop(property, obj) }
          color="primary"
        />
      </div>
    </div>
  </div>
)

const makeArrayToggles = (targetBooleans, property, objects, setter, parentIndex) => map(
  (item) => item === 'Hosts' ? 
    <div key={parentIndex + '_' + item} /> : (
    <div key={parentIndex + '_' + item} style={{ width: '35vw', paddingLeft: 40, paddingRight: 0, flexDirection: 'row', display: 'flex' }}>
      <div style={{ flex: 1, alignSelf: 'center', justifySelf: 'flex-end', textAlign: 'right', paddingRight: 20 }}>
        { item }
      </div>
      <div>
        <Switch
          checked={includes(item, targetBooleans)}
          onChange={e => {
            setter(
              set(
                lensPath([parentIndex, property]),
                !e.target.checked ?
                reject(equals(item), targetBooleans) :
                append(item, targetBooleans)
                ,
                objects
              )
            )
          }}
          value={ item }
          color="primary"
        />
      </div>
    </div>
  )
)

const makePropertyRows = (obj, objects, setter, parentIndex) => map(
  property => {
    //if (property === 'id') return <div key={ property + ':' + parentIndex + 'id' } />
    if (property === 'enabledServices') return <div key={ property + ':' + parentIndex + 'id' } />
    if (typeof prop(property, obj) === 'boolean') {
      return (
      <BooleanRow
        obj={obj}
        property={property}
        objects={objects}
        setter={setter}
        parentIndex={parentIndex} />
    )}
    return (
      <div key={ property + ':' + parentIndex } style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
        <div style={{ minWidth: 150, padding: 5 }}>
          { capitalize(property) }
        </div>
        { property === 'enabledServices' ?
        <div>
          { makeArrayToggles(
            prop(property, obj),
            property,
            objects,
            setter,
            parentIndex
          )(getEnabledServices()) }
        </div> :
        <div style={{ backgroundColor: 'white', borderRadius: 3 }}>
          <TextField
            id="object-property"
            disabled={property === 'id'}
            color="primary"
						variant="outlined"
						multiline
            value={ prop(property, obj) }
            onChange={
              (e) => {
                if (e.target.value.length > charLimits[property]) {
                  alert('Character limit for ' + property + ' is ' + charLimits[property] + '.')
                  return 
                }
                if (property === 'username' && !startsWith('tienoo/', e.target.value)) {
                  return
                }
                setter(
                  set(
                    lensPath([parentIndex, property]),
                    e.target.value,
                    objects
                  )
                )
              }
            }
          />
        </div> }
      </div>
    )
  }
)

export const makeObjectView = (setter, deleteEnabled = true) => objects => mapIndexed(
  (obj, index) => (
    <div key={ prop('id', obj) } className="object-container">
      <div className="object-content">
       { makePropertyRows(obj, objects, setter, index)(keys(obj)) }
       { deleteEnabled && <div style={{ paddingTop: 15 }}>
        <Button
          id="object-delete"
          variant="outlined"
          color="secondary"
          onClick={() =>
            setter(
              remove(index, 1, objects)
            )
          }
          startIcon={<DeleteIcon />}>
          Delete
        </Button>
       </div>
       }
       </div>
    </div>
  )
)(objects)

export const LoadingIndicator = (props) => (
  <div
    style={{
      width: '100%'
    }}>
    <div style={{ margin: 'auto', width: 50 }}>
      <CircularProgress color="secondary" />
    </div>
  </div>
)
