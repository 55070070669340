import React, { useState, useEffect } from 'react'
import './index.css'
import { LoadingIndicator } from '../_generics'
import CustomModal from '../_generics/CustomModal'
import { path as rpath, split, repeat, remove, reject, equals, insert, dropLast, compose, reduce, map, keys, toPairs, filter, startsWith, assoc, append, set, lensPath, dissoc, includes, take } from 'ramda'
import { mapIndexed, getLeafs, getUsername, b64toBlob, renameKey, copyPages, copyPage, pastePages, pastePage, copySingleLangPage, pasteSingleLangPage, copyFullLanguaguge, pasteFullSingleLanguage } from '../../utils';
import { putSite_v2, putSite_backup_v2, getSite, getSiteStructure, getSiteContent, publishSite, getEnabledViews, getBaseSite_v2, getHosts, getBaseSite_v2_byName, putSite_v2_byName, translateFullLanguageVersion } from '../../services';
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { putAsset, processLogo, reduceQuality } from '../../services/assets';
import { translate, textToSpeech } from '../../services/google';
import { FiUploadCloud, FiSave, FiRepeat, FiFlag } from "react-icons/fi"
import { IoMdRemoveCircleOutline, IoMdArrowRoundDown, IoMdArrowRoundUp, IoIosPaper } from "react-icons/io"
import { MdAddCircleOutline, MdTranslate, MdAddCircle } from "react-icons/md"
import { RiDeleteBinLine } from "react-icons/ri"
import { BsBoxArrowInRight } from "react-icons/bs"
import { FaArrowRight, FaUser, FaGlobeAmericas } from "react-icons/fa"
import { AiFillEyeInvisible, AiFillEye, AiFillCaretUp, AiFillCaretDown } from "react-icons/ai"
import { IconContext } from "react-icons"
import tienoo_logo from './tienoo_red.png'
import { logout } from '../../services';
import MicRecorder from 'mic-recorder-to-mp3';

const AudioRecord = ({
  path,
  index,
  property,
  Mp3Recorder,
  setLoading,
  setSite,
  site
}) => {
  const [ recording, setRecording ] = useState(false)

  return (
    <div>
      <label className="file-upload" htmlFor={path.toString() + 'VidR' + index + property[0]}>{ !recording ? 'Record' : 'Recording...' }</label>
      <input
        type="button"
        id={path.toString() + 'VidR' + index + property[0]}
        style={{ display: 'none' }}
        onClick={() => {

          if (!Mp3Recorder) {
            console.log('No mp3-recorder found.')
            return
          }
          if (!recording) {
            Mp3Recorder
              .start()
              .then(() => {
              setRecording(true)
              }).catch((e) => console.error(e));
          } else {
            Mp3Recorder
              .stop()
              .getMp3()
              .then(([buffer, blob]) => {
                setRecording(false)
                let player = document.getElementById('player')
                if (!player) return 
                console.log('Audio-player found!')
                console.log('audio: ', blob)
                
                const fileName = 'REC:' + Math.floor(Math.random() * 10000000) + '.mp3'
                setLoading(true)
                putAsset(
                  fileName,
                  blob,
                  (url) => {
                    setLoading(false)
                    const blobURL = window.URL.createObjectURL(blob)
                    player.src = blobURL
                    setSite(
                      set(
                        lensPath(
                          append(
                            property[0],
                            append(index, path)
                          )
                        ),
                        url,
                        site
                      )
                    )
                  }
                )                                      

              }).catch((e) => console.log(e));
          }
        }} />
    </div>
  )
}

const saveSite = (site) => {
  return putSite_v2(site).then(
    res => {
      alert(res)
      putSite_backup_v2(site)
      return 'success'
    }
  )
}

const pageTemplate = {
  component: [{
    type: 'menu'
  }],
  infoScroll: [{
    text: ``,
    sound: ''
  }],
  text: [{
    content: ``,
    sound: ''
  }],
  picture: [],
  video: [],
  map: [],
  buttons: []
}

const addPage = (setSite, site, newPageName, setNewPageName, languages) => {
  setSite(
    assoc(
      newPageName,
      reduce(
        (acc, value) => assoc(value, pageTemplate, acc),
        {},
        languages
      ),
      site
    )
  )
  setNewPageName('')
}

const getPlaceholder = (property) => {
  if (property[0] === 'img') {
    return "Link or upload own."
  }
  if (property[0] === 'sound') {
    return "Link, upload or text-to-speech."
  }
  if (property[0] === 'source') {
    return "Link or upload own. Youtube videos should use the embed code ~ https://www.youtube.com/embed/Ppjv0H-Yt5Q"
  }
  if (property[0] === 'text') {
    return "First text, Second text, Third text, ..."
  }
  if (property[0] === 'embed') {
    return "Address, search term, or Google Maps embed code."
  }
  if (property[0] === 'linkTo') {
    return "/CITY/Kategoriat, https://google.com and so on."
  }
  return 'Text. Write your own.'
}

const ComponentRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading }) => {
  return (
    <div className="editor-row nested">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div key={path.toString() + index} className="button-editor">
                { map(
                    property => (
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          <input
                            value={ property[1] }
                            placeholder={
                              (property[0] === 'img' || property[0] === 'sound' || property[0] === 'source') ? "https://www.example.com/img" : 'Keep "menu". Only in /CITY should be "root".'
                            }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                          style={{ width: '100%' }} />
                        </div>
                      </div>
                    ),
                    toPairs(obj)
                  ) }
              </div>
            ),
            pair[1]
          )
        }
      </div>
    </div>
  )
}

const UrlPathRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading }) => {
  return (
    <div className="editor-row">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        <input
          value={ pair[1] }
          placeholder="Path to appear on browsers url-bar."
          onChange={
            e => setSite(
              set(
                lensPath(path),
                e.target.value,
                site
              )
            )
          }
          style={{ width: '100%' }} />
      </div>
    </div>
  )
}

const VideoRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-0">
      <div className="property-key">
        { pair[0] === 'video' ? 'Videos' : pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div
                key={path.toString() + index}
                className="button-editor"
                style={{ opacity: pair[1][index].hidden ? 0.5 : 1 }}>
                { map(
                    property => (
                      property[0] === 'hidden' ? (
                        <div key="hidden" />
                      ) :
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'videoT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'videoT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          console.log('Put response after textToSpeech: ', res)
                                          setLoading(false)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'videoProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'videoProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  console.log(file)
                                  setLoading(true)
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { (property[0] === 'title' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'textPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'textPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )   
                                  }                             
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
                <div
                  style={{ textAlign: 'right' }}>
                  <div
                    style={{ display: 'inline', color: 'blue', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index + 1, 1),
                            insert(index === 0 ? 0 : index - 1, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundUp />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'green', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index, 1),
                            insert(index === (pair[1].length - 1) ? index + 1 : index + 2, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundDown />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath([...path, index]),
                          Object.assign({}, pair[1][index], { hidden: !pair[1][index].hidden }),
                          site
                        )
                      )
                    }>
                    { pair[1][index].hidden ? (
                      <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEye />
                    </IconContext.Provider>
                    ) : (
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEyeInvisible />
                    </IconContext.Provider> )}
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position. If some other language doesnt have same amount of elements the target element will be appended.')) {
                        setSite(
                          compose(
                            ...languages.map((lang) => {
                              /* if (!rpath([ path[0], lang, path[2], index ], site)) {
                                return _ => _
                              } */
                              if (rpath([ path[0], lang, path[2], index ], site)) {
                                return set(
                                  lensPath(append(index, [ path[0], lang, path[2] ])),
                                  rpath([ path[0], path[1], path[2], index ], site),
                                )
                              }
                              return set(
                                lensPath([ path[0], lang, path[2] ]),
                                append(
                                  rpath([ path[0], path[1], path[2], index ], site),
                                  rpath([ path[0], lang, path[2] ], site),
                                )
                              )
                            })
                          )(site)
                        )                                
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <BsBoxArrowInRight />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'red', textAlign: 'right', fontSize: '10px', paddingRight: 0, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Do you want to delete?')) {
                        setSite(
                          set(
                            lensPath(path),
                            remove(index, 1, pair[1]),
                            site
                          )
                        )
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity red zero-padding" }}>
                      <RiDeleteBinLine />
                    </IconContext.Provider>
                  </div>
                </div>
              </div>
            ),
            pair[1]
          )
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* <div
          style={{ color: 'red', textAlign: 'right', fontSize: '25px', paddingRight: 10, display: 'inline'  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                dropLast(1, pair[1]),
                site
              )
            )
          }>
          -
        </div> */}
        <div
          style={{ color: 'green', textAlign: 'right', fontSize: '25px', paddingRight: 10  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                append({ title: '', sound: '', source: '' }, pair[1]),
                site
              )
            )
          }>
          +
        </div>
      </div>
    </div>
  )
}

const SoundRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading }) => {
  return (
    <div className="editor-row">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        <input
          value={ pair[1] }
          onChange={
            e => setSite(
              set(
                lensPath(path),
                e.target.value,
                site
              )
            )
          }
          style={{ width: '100%' }} />
      </div>
    </div>
  )
}

const ButtonsRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-0">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div
                key={path.toString() + index}
                className="button-editor"
                style={{ opacity: pair[1][index].hidden ? 0.5 : 1 }}>
                { map(
                    property => (
                      property[0] === 'hidden' ? (
                        <div key="hidden" />
                      ) :
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'buttonT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'buttonT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          setLoading(false)
                                          console.log('Put response after textToSpeech: ', res)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'buttonProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'buttonProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  const _ = file.name
                                  if (_.endsWith('.jpg') || _.endsWith('.jpeg') || _.endsWith('.png')) {
                                    file.arrayBuffer().then(buffer => {
                                      reduceQuality(buffer).then(
                                        blob => {
  
                                          setLoading(true)
                                          putAsset(
                                            file.name,
                                            blob,
                                            fileUrl => {
                                              setLoading(false)
                                              setSite(
                                                set(
                                                  lensPath(
                                                    append(
                                                      property[0],
                                                      append(index, path)
                                                    )
                                                  ),
                                                  fileUrl,
                                                  site
                                                )
                                              )
                                            }
                                          )
  
                                        }
                                      )
                                    })   
                                  } else {
                                    setLoading(true)
                                    putAsset(
                                      file.name,
                                      file,
                                      fileUrl => {
                                        setLoading(false)
                                        setSite(
                                          set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, path)
                                              )
                                            ),
                                            fileUrl,
                                            site
                                          )
                                        )
                                      }
                                    )
                                  }
                                }} />
                            </div> }
                            { property[0] === 'img' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'buttonPropertyL' + index + property[0]}>Upload Logo</label>
                              <input
                                id={path.toString() + 'buttonPropertyL' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]

                                  file.arrayBuffer().then(buffer => {
                                    processLogo(buffer).then(
                                      logoBlob => {

                                        setLoading(true)
                                        putAsset(
                                          file.name,
                                          logoBlob,
                                          fileUrl => {
                                            setLoading(false)
                                            setSite(
                                              set(
                                                lensPath(
                                                  append(
                                                    property[0],
                                                    append(index, path)
                                                  )
                                                ),
                                                fileUrl,
                                                site
                                              )
                                            )
                                          }
                                        )

                                      }
                                    )
                                  })                                
                                }} />
                            </div> }
                            { (property[0] === 'img' || property[0] === 'title' || property[0] === 'linkTo') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'buttonPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'buttonPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )   
                                  }                             
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
                <div
                  style={{ textAlign: 'right' }}>
                  <div
                    style={{ display: 'inline', color: 'blue', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index + 1, 1),
                            insert(index === 0 ? 0 : index - 1, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundUp />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'green', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index, 1),
                            insert(index === (pair[1].length - 1) ? index + 1 : index + 2, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundDown />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath([...path, index]),
                          Object.assign({}, pair[1][index], { hidden: !pair[1][index].hidden }),
                          site
                        )
                      )
                    }>
                    { pair[1][index].hidden ? (
                      <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEye />
                    </IconContext.Provider>
                    ) : (
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEyeInvisible />
                    </IconContext.Provider> )}
                  </div>
                  {/* <div
                    className="hover-opacity"
                    style={{ display: 'inline', padding: 5, paddingBottom: 20, margin: 20 }}>
                    Copy button
                  </div> */}
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position. If some other language doesnt have same amount of elements the target element will be appended.')) {
                        setSite(
                          compose(
                            ...languages.map((lang) => {
                              /* if (!rpath([ path[0], lang, path[2], index ], site)) {
                                return _ => _
                              } */
                              if (rpath([ path[0], lang, path[2], index ], site)) {
                                return set(
                                  lensPath(append(index, [ path[0], lang, path[2] ])),
                                  rpath([ path[0], path[1], path[2], index ], site),
                                )
                              }
                              return set(
                                lensPath([ path[0], lang, path[2] ]),
                                append(
                                  rpath([ path[0], path[1], path[2], index ], site),
                                  rpath([ path[0], lang, path[2] ], site),
                                )
                              )
                            })
                          )(site)
                        )                                
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <BsBoxArrowInRight />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'red', textAlign: 'right', fontSize: '10px', paddingRight: 5, paddingTop: 3 }}
                    onClick={e => {
                      if (window.confirm('Do you want to delete?')) {
                        setSite(
                          set(
                            lensPath(path),
                            remove(index, 1, pair[1]),
                            site
                          )
                        )
                      }
                    }}>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity red zero-padding" }}>
                      <RiDeleteBinLine />
                    </IconContext.Provider>
                  </div>
                </div>
              </div>
            ),
            pair[1]
          )
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* <div
          style={{ color: 'red', textAlign: 'right', fontSize: '25px', paddingRight: 10, display: 'inline'  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                dropLast(1, pair[1]),
                site
              )
            )
          }>
          -
        </div> */}
        <div
          style={{ color: 'green', textAlign: 'right', fontSize: '25px', paddingRight: 10  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                append({ title: '', linkTo: '', img: '', sound: '' }, pair[1]),
                site
              )
            )
          }>
          +
        </div>
      </div>
    </div>
  )
}

const InfoScrollRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-1">
      <div className="property-key">
        { pair[0] === 'infoScroll' ? 'Scroller' : pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div key={path.toString() + index} className="button-editor">
                { map(
                    property => (
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'infoScrollT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'infoScrollT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          setLoading(false)
                                          console.log('Put response after textToSpeech: ', res)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'infoScrollP' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'infoScrollP' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  console.log(file)
                                  setLoading(true)
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'text' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'scrollPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'scrollPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )   
                                  }                             
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
              </div>
            ),
            pair[1]
          )
        }
      </div>
    </div>
  )
}

const TextRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-1">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div key={path.toString() + index} className="button-editor">
                { map(
                    property => (
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <textarea
                              rows={3}
                              placeholder="Type text here."
                              value={ property[1] }
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                              style={{ width: '70%' }} />
                              { (property[0] === 'title' || property[0] === 'content') &&
                              <div>
                                <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                                <input
                                  id={path.toString() + 'mapT' + index + property[0]}
                                  style={{ display: 'none' }}
                                  type="button"
                                  onClick={(e) => {
                                    setLoading(true)
                                    translate(property[1], translationOrigin, translationTarget).then(
                                      res => {
                                        console.log('asd', res, property[1])
                                        setLoading(false)
                                        setSite(
                                          set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, path)
                                              )
                                            ),
                                            res,
                                            site
                                          )
                                        ) 
                                      }
                                    )
                                  }} />
                            </div> }
                            { property[0] === 'content' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'textPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'textPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )   
                                  }                             
                                }} />
                            </div> }
                            </div> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'textT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'textT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          setLoading(false)
                                          console.log('Put response after textToSpeech: ', res)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'textProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'textProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  console.log(file)
                                  setLoading(true)
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'content' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'textPropertyTo' + index + property[0]}>To-all-languages</label>
                              <input
                                id={path.toString() + 'textPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )   
                                  }                             
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
              </div>
            ),
            pair[1]
          )
        }
      </div>
    </div>
  )
}

const MapRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-1">
      <div className="property-key">
        { pair[0] === 'map' ? 'Maps' : pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div
                key={path.toString() + index}
                className="button-editor"
                style={{ opacity: pair[1][index].hidden ? 0.5 : 1 }}>
                { map(
                    property => (
                      property[0] === 'hidden' ? (
                        <div key="hidden" />
                      ) :
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          console.log('Put response after textToSpeech: ', res)
                                          setLoading(false)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'mapProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  console.log(file)
                                  setLoading(true)
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { (property[0] === 'embed' || property[0] === 'title') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'mapPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )    
                                  }                            
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
                <div
                  style={{ textAlign: 'right' }}>
                  <div
                    style={{ display: 'inline', color: 'blue', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index + 1, 1),
                            insert(index === 0 ? 0 : index - 1, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundUp />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'green', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index, 1),
                            insert(index === (pair[1].length - 1) ? index + 1 : index + 2, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundDown />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath([...path, index]),
                          Object.assign({}, pair[1][index], { hidden: !pair[1][index].hidden }),
                          site
                        )
                      )
                    }>
                    { pair[1][index].hidden ? (
                      <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEye />
                    </IconContext.Provider>
                    ) : (
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEyeInvisible />
                    </IconContext.Provider> )}
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position. If some other language doesnt have same amount of elements the target element will be appended.')) {
                        setSite(
                          compose(
                            ...languages.map((lang) => {
                              /* if (!rpath([ path[0], lang, path[2], index ], site)) {
                                return _ => _
                              } */
                              if (rpath([ path[0], lang, path[2], index ], site)) {
                                return set(
                                  lensPath(append(index, [ path[0], lang, path[2] ])),
                                  rpath([ path[0], path[1], path[2], index ], site),
                                )
                              }
                              return set(
                                lensPath([ path[0], lang, path[2] ]),
                                append(
                                  rpath([ path[0], path[1], path[2], index ], site),
                                  rpath([ path[0], lang, path[2] ], site),
                                )
                              )
                            })
                          )(site)
                        )                                
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <BsBoxArrowInRight />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'red', textAlign: 'right', fontSize: '10px', paddingRight: 5, paddingTop: 3 }}
                    onClick={e => {
                      if (window.confirm('Do you want to delete?')) {
                        setSite(
                          set(
                            lensPath(path),
                            remove(index, 1, pair[1]),
                            site
                          )
                        )
                      }
                    }}>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity red zero-padding" }}>
                      <RiDeleteBinLine />
                    </IconContext.Provider>
                  </div>
                </div>
              </div>
            ),
            pair[1]
          )
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* <div
          style={{ color: 'red', textAlign: 'right', fontSize: '25px', paddingRight: 10, display: 'inline'  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                dropLast(1, pair[1]),
                site
              )
            )
          }>
          -
        </div> */}
        <div
          style={{ color: 'green', textAlign: 'right', fontSize: '25px', paddingRight: 10  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                append({ title: '', sound: '', embed: '' }, pair[1]),
                site
              )
            )
          }>
          +
        </div>
      </div>
    </div>
  )
}

const LinksRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading }) => {
  return (
    <div className="editor-row nested">
      <div className="property-key">
        { pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div key={path.toString() + index} className="button-editor">
                { map(
                    property => (
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'mapT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'mapT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'linkT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'linkT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          console.log('Put response after textToSpeech: ', res)
                                          setLoading(false)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'linkProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'linkProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  setLoading(true)
                                  const file = e.target.files[0]
                                  console.log(file, index, path, property[0])
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
              </div>
            ),
            pair[1]
          )
        }
      </div>
      <div
        style={{ color: 'green', textAlign: 'right', fontSize: '25px', paddingRight: 10  }}
        onClick={
          e => setSite(
            set(
              lensPath(path),
              append({ title: '', address: '', img: '', sound: '' }, pair[1]),
              site
            )
          )
        }>
        +
      </div>
    </div>
  )
}

const ImgRowEditor = ({ pair, path, setSite, site, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => {
  return (
    <div className="editor-row nested nested-0">
      <div className="property-key">
        { pair[0] === 'picture' ? 'Images' : pair[0] }
      </div>
      <div className="property-value">
        {
          mapIndexed(
            (obj, index) => (
              <div
                key={path.toString() + index}
                className="button-editor"
                style={{ opacity: pair[1][index].hidden ? 0.5 : 1 }}>
                { map(
                    property => (
                      property[0] === 'hidden' ? (
                        <div key="hidden" />
                      ) :
                      <div key={path.toString() + property[0]} className="editor-row nest">
                        <div style={{ flex: 1 }}>
                          { property[0] }
                        </div>
                        <div style={{ flex: 5 }}>
                          { property[0] === 'content' ?
                          <textarea
                            rows={3}
                            value={ property[1] }
                            onChange={
                              e => setSite(
                                set(
                                  lensPath(
                                    append(
                                      property[0],
                                      append(index, path)
                                    )
                                  ),
                                  e.target.value,
                                  site
                                )
                              )
                            }
                            style={{ width: '100%' }} /> : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <input
                              value={ property[1] }
                              placeholder={getPlaceholder(property)}
                              onChange={
                                e => setSite(
                                  set(
                                    lensPath(
                                      append(
                                        property[0],
                                        append(index, path)
                                      )
                                    ),
                                    e.target.value,
                                    site
                                  )
                                )
                              }
                            style={{ flex: 1 }} />
                            { (property[0] === 'title' || property[0] === 'content') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'picT' + index + property[0]}>Translate</label>
                              <input
                                id={path.toString() + 'picT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  translate(property[1], translationOrigin, translationTarget).then(
                                    res => {
                                      console.log('asd', res, property[1])
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          res,
                                          site
                                        )
                                      ) 
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'picT' + index + property[0]}>To-speech</label>
                              <input
                                id={path.toString() + 'picT' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  setLoading(true)
                                  textToSpeech(
                                    property[1],
                                    textToSpeechLanguage,
                                    textToSpeechVoice
                                  ).then(
                                    base64Audio => {
                                      putAsset(
                                        property[1] + '.mp3',
                                        b64toBlob(base64Audio, 'audio/mp3'),
                                        (res) => {
                                          console.log('Put response after textToSpeech: ', res)
                                          setLoading(false)
                                          setSite(
                                            set(
                                              lensPath(
                                                append(
                                                  property[0],
                                                  append(index, path)
                                                )
                                              ),
                                              res,
                                              site
                                            )
                                          )
                                        }
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { property[0] === 'sound' && (
                              <AudioRecord
                                path={path}
                                index={index}
                                property={property} 
                                Mp3Recorder={Mp3Recorder}
                                setLoading={setLoading} 
                                setSite={setSite} 
                                site={site} /> 
                            ) }
                            { (property[0] === 'sound' || property[0] === 'img' || property[0] === 'source') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'picProperty' + index + property[0]}>Upload</label>
                              <input
                                id={path.toString() + 'picProperty' + index + property[0]}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={(e) => {
                                  const file = e.target.files[0]
                                  console.log(file)
                                  setLoading(true)
                                  putAsset(
                                    file.name,
                                    file,
                                    fileUrl => {
                                      setLoading(false)
                                      setSite(
                                        set(
                                          lensPath(
                                            append(
                                              property[0],
                                              append(index, path)
                                            )
                                          ),
                                          fileUrl,
                                          site
                                        )
                                      )
                                    }
                                  )
                                }} />
                            </div> }
                            { (property[0] === 'source' || property[0] === 'title') &&
                            <div>
                              <label className="file-upload" htmlFor={path.toString() + 'imgPropertyTo' + index + property[0]}>To all languages</label>
                              <input
                                id={path.toString() + 'imgPropertyTo' + index + property[0]}
                                style={{ display: 'none' }}
                                type="button"
                                onClick={(e) => {
                                  if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position.')) {
                                    setSite(
                                      compose(
                                        ...languages.map((lang) => {
                                          if (!rpath([ path[0], lang, path[2], index ], site)) {
                                            return _ => _
                                          }
                                          return set(
                                            lensPath(
                                              append(
                                                property[0],
                                                append(index, [ path[0], lang, path[2] ])
                                              )
                                            ),
                                            rpath([ path[0], path[1], path[2], index, property[0] ], site),
                                          )
                                        })
                                      )(site)
                                    )    
                                  }                            
                                }} />
                            </div> }
                          </div> )}
                        </div> 
                      </div>
                    ),
                    toPairs(obj)
                  ) }
                <div
                  style={{ textAlign: 'right' }}>
                  <div
                    style={{ display: 'inline', color: 'blue', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index + 1, 1),
                            insert(index === 0 ? 0 : index - 1, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundUp />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'green', textAlign: 'right', fontSize: '10px', paddingRight: 10, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath(path),
                          compose(
                            remove(index, 1),
                            insert(index === (pair[1].length - 1) ? index + 1 : index + 2, pair[1][index])
                          )(pair[1]),
                          site
                        )
                      )
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <IoMdArrowRoundDown />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 3 }}
                    onClick={
                      e => setSite(
                        set(
                          lensPath([...path, index]),
                          Object.assign({}, pair[1][index], { hidden: !pair[1][index].hidden }),
                          site
                        )
                      )
                    }>
                    { pair[1][index].hidden ? (
                      <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEye />
                    </IconContext.Provider>
                    ) : (
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <AiFillEyeInvisible />
                    </IconContext.Provider> )}
                  </div>
                  <div
                    style={{ display: 'inline', color: 'black', textAlign: 'right', fontSize: '10px', paddingRight: 30, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Copy the element to all other languages? It will override previous elemnent in the same position. If some other language doesnt have same amount of elements the target element will be appended.')) {
                        setSite(
                          compose(
                            ...languages.map((lang) => {
                              /* if (!rpath([ path[0], lang, path[2], index ], site)) {
                                return _ => _
                              } */
                              if (rpath([ path[0], lang, path[2], index ], site)) {
                                return set(
                                  lensPath(append(index, [ path[0], lang, path[2] ])),
                                  rpath([ path[0], path[1], path[2], index ], site),
                                )
                              }
                              return set(
                                lensPath([ path[0], lang, path[2] ]),
                                append(
                                  rpath([ path[0], path[1], path[2], index ], site),
                                  rpath([ path[0], lang, path[2] ], site),
                                )
                              )
                            })
                          )(site)
                        )                                
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark zero-padding" }}>
                      <BsBoxArrowInRight />
                    </IconContext.Provider>
                  </div>
                  <div
                    style={{ display: 'inline', color: 'red', textAlign: 'right', fontSize: '10px', paddingRight: 0, paddingTop: 6 }}
                    onClick={e => {
                      if (window.confirm('Do you want to delete?')) {
                        setSite(
                          set(
                            lensPath(path),
                            remove(index, 1, pair[1]),
                            site
                          )
                        )
                      }
                    }
                    }>
                    <IconContext.Provider value={{ className: "small-icon hover-opacity red zero-padding" }}>
                      <RiDeleteBinLine />
                    </IconContext.Provider>
                  </div>
                </div>
              </div>
            ),
            pair[1]
          )
        }
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {/* <div
          style={{ color: 'red', textAlign: 'right', fontSize: '25px', paddingRight: 10, display: 'inline'  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                dropLast(1, pair[1]),
                site
              )
            )
          }>
          -
        </div> */}
        <div
          style={{ color: 'green', textAlign: 'right', fontSize: '25px', paddingRight: 10  }}
          onClick={
            e => setSite(
              set(
                lensPath(path),
                append({ title: '', sound: '', source: '' }, pair[1]),
                site
              )
            )
          }>
          +
        </div>
      </div>
    </div>
  )
}


const PropertyEditor = ({ path, setSite, site, pair, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, languages, Mp3Recorder }) => (
  <div key={ pair[0] }>
    { /* pair[0] === 'component' &&
    <ComponentRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      languages={languages}
      setSite={setSite}
      site={site} /> */}
    { pair[0] === 'path' &&
    <UrlPathRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'picture' &&
    <ImgRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'video' &&
    <VideoRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'sound' &&
    <SoundRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'buttons' &&
    <ButtonsRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'text' &&
    <TextRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { /*pair[0] === 'infoScroll' &&
    <InfoScrollRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> */}
    { pair[0] === 'map' &&
    <MapRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'links' &&
    <LinksRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
    { pair[0] === 'img' &&
    <ImgRowEditor
      pair={pair}
      path={append(pair[0], path)}
      textToSpeechLanguage={textToSpeechLanguage}
      textToSpeechVoice={textToSpeechVoice}
      translationOrigin={translationOrigin}
      translationTarget={translationTarget}
      setLoading={setLoading}
      Mp3Recorder={Mp3Recorder}
      languages={languages}
      setSite={setSite}
      site={site} /> }
  </div>
)

// index 0 of page is key, 1 is value
const PageEditor = ({ setSite, site, page, languages, textToSpeechLanguage, textToSpeechVoice, translationOrigin, translationTarget, setLoading, selectedLang, setSelectedLang, Mp3Recorder }) => {
  // const [ selectedLang, setSelectedLang ] = useState(languages[0])

  // if (getUsername() !== 'tienoo' && !includes(page[0], getLeafs(site))) return <div />
  return (
    <div className="page-editor" key={ page[0] }>
      <div style={{ paddingBottom: 20, padding: 15, borderBottom: '1px solid rgb(80,80,80)' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="page-title">
            <textarea
              className="page-title"
              value={ page[0] }
              placeholder={'Page title.'}
              onChange={
                e => setSite(
                  renameKey(page[0], e.target.value, site)
                )
              }
            style={{ flex: 1, width: '100%' }} />
          </div>
          <div style={{ paddingLeft: 20, paddingBottom: 5 }}>
            <Dropdown
              controlClassName="city-dropdown"
              options={
                map(
                  _ => ({
                    label: _,
                    value: _
                  }),
                  languages
                )
              }
              onChange={_ => setSelectedLang(_.value) }
              value={selectedLang} />
          </div>

        <div style={{ flexDirection: 'column' }}>

          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => copySingleLangPage(page[1][selectedLang], site)}>
               COPY SINGLE LANG
            </div>
          </div>
          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => pasteSingleLangPage(page[0], site, setSite, selectedLang)}>
               PASTE SINGLE LANG
            </div>
          </div>

          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => copyPage(page[1], site)}>
               COPY ALL LANGS
            </div>
          </div>
          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => pastePage(page[0], site, setSite)}>
               PASTE ALL LANGS
            </div>
          </div>
          
          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => copyPages(page[0], site)}>
               COPY HIERARCY
            </div>
          </div>

          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => {
                if (window.confirm('Copy the page to all other languages? It will override previous elemnent in the same position.')) {
                  setSite(
                    compose(
                      ...languages.map((lang) => {
                          return set(
                            lensPath([ page[0], lang ]),
                            rpath([ page[0], selectedLang ], site),
                          )
                        }
                      )
                    )(site)
                  )                                
                }
              }}>
              TO ALL LANGUAGES
            </div>
          </div>
          <div style={{ color: 'rgba(220,110,10)', paddingLeft: 30, flex: 1, textAlign: 'right', fontSize: 10, fontWeight: '700' }}>
            <div
              className="hover-opacity"
              onClick={e => {
                if (window.confirm('Do you want to delete the page?')) {
                  setSite(dissoc(page[0], site))
                }
              }}>
              DELETE
            </div>
          </div>
        </div>
        </div>
      </div>
      { 
        map(
          pair => (
            <PropertyEditor
              key={pair[0]}
              path={[ page[0], selectedLang ]}
              setSite={setSite}
              site={site}
              pair={pair}
              languages={languages}
              textToSpeechLanguage={textToSpeechLanguage}
              textToSpeechVoice={textToSpeechVoice}
              translationOrigin={translationOrigin}
              translationTarget={translationTarget}
              setLoading={setLoading}
              Mp3Recorder={Mp3Recorder} />
          ),
          toPairs(page[1][selectedLang])
        )
      }
    </div>
  )
}

const FolderNode = ({ pageName, childs, setSearchFilter, path, depth }) => {
  const [ opened, setOpened ] = useState(pageName === '')

  return (
    <div className="folder-node">
      <div className="folder-row">
        <div
          className="folder-name hover-opacity"
          onClick={() => { childs && setOpened(!opened)} }>
          { repeat('.', depth).map((_, index) =>
            <div key={'depth_' + path + '_' + index} style={{ width: 40, borderLeft: '1px solid rgb(230,230,230)' }} />
          )}
          <div>{ pageName + (childs ? ' /' : '') }</div>
        </div>
        { !opened &&
        <div
          className="folder-filter-btn hover-opacity"
          onClick={() => setSearchFilter(path)}>
          <IconContext.Provider value={{ className: "tiny-icon-2 hover-opacity green zero-padding" }}>
            <FaArrowRight />
          </IconContext.Provider>
        </div> }
      </div>
      { opened && childs &&
        map(child => {
          return (
            <FolderNode
              key={path + '/' + child[0]}
              path={path + '/' + child[0]}
              depth={depth + 1}
              pageName={child[0]}
              childs={child[1]}
              setSearchFilter={setSearchFilter} />
          )
        },
        toPairs(childs)
        )
      }
    </div>
  )
}

const FolderNavigation = ({ tree, setSearchFilter }) => {
  return (
    <div>
      { map(child => {
          return (
            <FolderNode
              key={child[0]}
              path={child[0]}
              depth={0}
              pageName={child[0]}
              childs={child[1]}
              setSearchFilter={setSearchFilter}/>
          )
        },
        toPairs(tree)
      )}
    </div>
  )
}

const makeSubstringTree = (site) => {
  const sortedKeys = keys(site).sort((a, b) => {
    if (a.length > b.length) return 1
    if (a.length < b.length) return -1
    return a.localeCompare(b)
  })

  let tree = {} 

  for (const pageName of sortedKeys) {
    const pageRoute = split('/', pageName)
    tree = set(
      lensPath(pageRoute),
      null,
      tree
    )
  }

  return tree
}

export default ({ setView, currentView, views }) => {
  const [ fetched, setFetched ] = useState(false)
  const [ site, setSite ] = useState(null)
  const [ propertyFilter, setPropertyFilter ] = useState('')
  const [ newPageName, setNewPageName ] = useState('')
  const [ newLanguage, setNewLanguage ] = useState('')
  const [ removeLanguage, setRemoveLanguage ] = useState('')
  const [ languages, setLanguages ] = useState([ "Suomi" ])
  const [ translationOrigin, setTranslationOrigin ] = useState('')
  const [ translationTarget, setTranslationTarget ] = useState('')
  const [ translationText, setTranslationText ] = useState('')
  const [ translationResult, setTranslationResult ] = useState('')
  const [ textToSpeechLanguage, setTextToSpeechLanguage ] = useState('')
  const [ textToSpeechVoice, setTextToSpeechVoice ] = useState('')
  const [ textToSpeechTarget, setTextToSpeechTarget ] = useState('')
  const [ newPageBaseLanguage, setNewPageBaseLanguage ] = useState('Suomi')
  const [ showTranslationsMenu, setShowTranslationsMenu ] = useState(false)
  const [ showPageActions, setShowPageActions ] = useState(false)
  const [ showLanguagesMenu, setShowLanguagesMenu ] = useState(false)
  const [ showAccountMenu, setShowAccountMenu ] = useState(false)
  const [ showSiteMenu, setShowSiteMenu ] = useState(false)
  const [ newPageModalVisibility, setNewPageModalVisibility ] = useState(false)
  const [ newLangmodalVisibility, setNewLangModalVisibility ] = useState(false)
  const [ deleteLangmodalVisibility, setDeleteLangModalVisibility ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ selectedLang, setSelectedLang ] = useState(languages[0])
  const [ Mp3Recorder, setMp3Recorder ] = useState(null)
  const [ autoTranslateLang, setAutoTranslateLang ] = useState('')
  const [ autoTextToSpeechLang, setAutoTextToSpeechLang ] = useState('')
  const [ autoTextToSpeechVoice, setAutoTextToSpeechVoice ] = useState('')
  const [ creatingNewLanguage, setCreatingNewLanguage ] = useState(false)

  const enabledViews = getEnabledViews()

  useEffect(() => {
    let mp3Record = new MicRecorder({ bitRate: 128 });
    setMp3Recorder(mp3Record)

    if (navigator && navigator.getUserMedia) {
      navigator.getUserMedia({ audio: true },
        () => {
          console.log('Permission Granted');
        },
        () => {
          console.log('Permission Denied');
        },
      );
    }
    if (!fetched) {
      // For local development
      /* setSite(mockSite)
      setFetched(true) */

      getBaseSite_v2().then(baseSite => {
        console.log('asd', baseSite)
        setSite(baseSite)
        if (baseSite && baseSite['/CITY']) {
          setLanguages(keys(baseSite['/CITY']))
        }
        setFetched(true) 
      })

 
      /*
      getSiteStructure().then(
        baseSite => {
          getSiteContent().then(
            siteStructure => {
              if (siteStructure && Object.keys(siteStructure).length !== 0) {
                setSite(siteStructure)
                if (siteStructure && siteStructure['/CITY']) {
                  setLanguages(keys(siteStructure['/CITY']))
                }
              } else {
                setSite(baseSite)
                if (baseSite && baseSite['/CITY']) {
                  setLanguages(keys(baseSite['/CITY']))
                }
              }
              setFetched(true) 

              /*
               getSiteContent().then(
                siteContent => {
                  getLeafs(siteStructure).forEach(
                    _ => {
                      console.log('leaf: ', _)
                      if (siteContent[_]) {
                        siteStructure[_] = siteContent[_]
                      }
                    }
                  )
                  setSite(siteStructure)
                  setFetched(true)
                }
              ) ---
              ) --original place for * /
            }
          )
        }
      )*/
    }
  }, [])

  if (!fetched) return (
    <CustomModal>
      <div className="loading-container">
        <LoadingIndicator />
        <div className="loading-description">
          Getting your data...
        </div>
      </div>
    </CustomModal>
  )
  return (
    <div className="Site">
      <div style={{ position: 'fixed', bottom: 20, left: 20, zIndex: 1000 }}>
        { loading && <LoadingIndicator /> }
      </div>
      <div className="controls">
        <div className="main-logo">
          <img
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'contain'
            }}
            src={tienoo_logo} />
        </div>

        <div className="username-area">
          {'Username: ' + getUsername() }
        </div>

        <div className="language-btn">
          <div
            className="action-btn hover-opacity"
            onClick={() => {
              setShowAccountMenu(!showAccountMenu)
            }}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FaUser />
            </IconContext.Provider>
            Account
            { showAccountMenu ? 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretUp />
            </IconContext.Provider> : 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretDown />
            </IconContext.Provider>
            }
          </div>
        </div>

        { showAccountMenu &&
        <div className="a">
          { getUsername() === 'tienoo' &&
          <div>
            { map(
              view => (
                <div
                  key={view.name}
                  className={"action-btn hover-opacity drawer-item" + ` ${view.name}-btn`}
                  onClick={() => setView(view)}>
                  { view.name }
                </div>
              ),
              filter(
                v => includes(v.name, enabledViews) && v.name !== 'Site',
                views
              )
            ) }
          </div> }
          <div
            className={"action-btn hover-opacity drawer-item" + ' logout-btn'}
            onClick={() => { logout(); window.location.reload() }}>
            Logout
          </div>
        </div> }

        <div className="language-btn">
          <div
            className="action-btn hover-opacity"
            onClick={() => {
              setShowLanguagesMenu(!showLanguagesMenu)
            }}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FaGlobeAmericas />
            </IconContext.Provider>
            Languages
            { showLanguagesMenu ? 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretUp />
            </IconContext.Provider> : 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretDown />
            </IconContext.Provider>
            }
          </div>
        </div>

        { showLanguagesMenu &&
        <div
          className="newpage-open-modal hover-opacity drawer-item"
          onClick={() => setNewLangModalVisibility(true)}>
          New
        </div> }
        { showLanguagesMenu &&
        <div
          className="action-btn deletepage-btn hover-opacity drawer-item"
          onClick={() => setDeleteLangModalVisibility(true)}>
          Delete
        </div> }

        <div className="language-btn">
          <div
            className="action-btn hover-opacity"
            onClick={() => {
              setShowTranslationsMenu(!showTranslationsMenu)
            }}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <MdTranslate />
            </IconContext.Provider>
            Translations
            { showTranslationsMenu ? 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretUp />
            </IconContext.Provider> : 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretDown />
            </IconContext.Provider>
            }
          </div>
        </div>

        { showTranslationsMenu &&
        <div>
          <div style={{ padding: 15, backgroundColor: 'rgb(245,245,245)', borderTop: '1px solid rgb(220,220,220)' }}>
          {/*  <div className="action-btn hover-opacity" onClick={
              () => textToSpeech(textToSpeechTarget, textToSpeechLanguage.trim(), textToSpeechVoice.trim()).then(
                result => console.log('ress<', result)
              )
              }>
              Speak
            </div>  */}
            <a
              className="external-link"
              href={'https://cloud.google.com/text-to-speech/docs/voices'}
              target="_blank">
              Text-to-speech codes
            </a>
            <input
              type="text"
              style={{ width: '95%', marginBottom: '5px', marginTop: 10, border: '1px solid rgb(146,146,146)', borderRight: '1px solid rgb(220,220,220)', borderBottom: '1px solid rgb(220,220,220)', backgroundColor: 'rgb(255,255,255)' }}
              value={textToSpeechLanguage}
              placeholder="Language (en-gb)"
              onChange={e => setTextToSpeechLanguage(e.target.value)} />
            <input
              type="text"
              style={{ width: '95%', marginBottom: '5px', border: '1px solid rgb(146,146,146)', borderRight: '1px solid rgb(220,220,220)', borderBottom: '1px solid rgb(220,220,220)', backgroundColor: 'rgb(255,255,255)' }}
              value={textToSpeechVoice}
              placeholder="Voice (en-GB-Standard-A)"
              onChange={e => setTextToSpeechVoice(e.target.value)} />
            {/* <input
              type="text"
              style={{ width: '95%', marginBottom: '20px' }}
              value={textToSpeechTarget}
              placeholder="Text to speak."
              onChange={e => setTextToSpeechTarget(e.target.value)} /> */}
          </div>
          <div style={{ padding: 15, backgroundColor: 'rgb(245,245,245)', borderBottom: '1px solid rgb(220,220,220)' }}>
            <a
              className="external-link"
              href={'https://cloud.google.com/translate/docs/languages'}
              target="_blank">
              Language codes
            </a>
            <input
              type="text"
              style={{ width: '95%', marginBottom: '5px', marginTop: 10, border: '1px solid rgb(146,146,146)', borderRight: '1px solid rgb(220,220,220)', borderBottom: '1px solid rgb(220,220,220)', backgroundColor: 'rgb(255,255,255)' }}
              value={translationOrigin}
              placeholder="From (fi)"
              onChange={e => setTranslationOrigin(e.target.value)} />
            <input
              type="text"
              style={{ width: '95%', marginBottom: '5px', border: '1px solid rgb(146,146,146)', borderRight: '1px solid rgb(220,220,220)', borderBottom: '1px solid rgb(220,220,220)', backgroundColor: 'rgb(255,255,255)' }}
              value={translationTarget}
              placeholder="To (en)"
              onChange={e => setTranslationTarget(e.target.value)} />
          </div>
        </div> }

        <div className="language-btn">
          <div
            className="action-btn hover-opacity"
            onClick={() => {
              setShowSiteMenu(!showSiteMenu)
            }}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <IoIosPaper />
            </IconContext.Provider>
            Site
            { showAccountMenu ? 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretUp />
            </IconContext.Provider> : 
            <IconContext.Provider value={{ className: "tiny-icon hover-opacity" }}>
              <AiFillCaretDown />
            </IconContext.Provider>
            }
          </div>
        </div>

        { showSiteMenu && 
        <div>
          <div
            className="site-btn hover-opacity drawer-item publish-btn"
            onClick={() => {
              if (!window.confirm('Publish the version with the updates on upcoming.tienoo.fi ?')) {
                return
              }
              setLoading(true)
              getHosts().then(
                storedHosts => {

                  getBaseSite_v2_byName(getUsername()).then(
                    res => {
                      putSite_v2_byName(res, getUsername()).then(
                        res2 => {
                          console.log('Done ', getUsername(), res2)
                          alert('Published upcoming.tienoo.fi to tienoo.fi')
                        }
                      )
                    }
                  )

                  /* publishSite('tienoo').then(
                    res => console.log('Publish result: ', res)
                  ) */

                  if (getUsername() === 'tienoo') {
                    storedHosts.forEach(host =>
                      /* publishSite(host.username).then(
                        res => console.log('Publish result: ', res)
                      ) */

                      getBaseSite_v2_byName(host.username).then(
                        res => {
                          putSite_v2_byName(res, host.username).then(
                            res2 => console.log('Done ', host.username, res2)
                          )
                        }
                      )

                    )
                  }                
                  setLoading(false)
                }
              ).catch(err => setLoading(false))
            }}>
 {/*            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FiUploadCloud />
            </IconContext.Provider> */}
            Publish
          </div>
          <div
            className="site-btn hover-opacity drawer-item"
            onClick={() => {
              if (!window.confirm('Save the version?')) {
                return
              }
              setLoading(true)
              saveSite(site).then(
                res => setLoading(false)
              )
            }}>
          {/*   <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FiSave />
            </IconContext.Provider> */}
            Save
          </div>
          <div
            className="action-btn hover-opacity site-btn drawer-item"
            onClick={() => pastePages(site, setSite)}
            style={{ backgroundColor: 'rgb(245,245,245)', borderBottom: '1px solid rgb(220,220,220)' }}>
      {/*       <IconContext.Provider value={{ className: "small-icon hover-opacity green" }}>
              <MdAddCircleOutline />
            </IconContext.Provider> */}
            Paste hierarcy
          </div>
          <div
            className="action-btn hover-opacity site-btn drawer-item"
            onClick={() => copyFullLanguaguge(site, selectedLang)}
            style={{ backgroundColor: 'rgb(245,245,245)', borderBottom: '1px solid rgb(220,220,220)' }}>
      {/*       <IconContext.Provider value={{ className: "small-icon hover-opacity green" }}>
              <MdAddCircleOutline />
            </IconContext.Provider> */}
            Copy full single language
          </div>
          <div
            className="action-btn hover-opacity site-btn drawer-item"
            onClick={() => pasteFullSingleLanguage(site, setSite, languages, setLanguages)}
            style={{ backgroundColor: 'rgb(245,245,245)', borderBottom: '1px solid rgb(220,220,220)' }}>
      {/*       <IconContext.Provider value={{ className: "small-icon hover-opacity green" }}>
              <MdAddCircleOutline />
            </IconContext.Provider> */}
            Paste full single language 
          </div>
        </div> }

        
     {/*    <div className="translate">
          <div className="action-btn hover-opacity" onClick={
            () => {
              setLoading(true)
              translate(translationText, translationOrigin, translationTarget).then(
              result => {
                setLoading(false)
                setTranslationResult(result)
              }
            )}}>
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark" }}>
              <FiRepeat />
            </IconContext.Provider>
            Translate
          </div>
          <input
            type="text"
            style={{ width: '80%', margin: 15, marginTop: 0 }}
            value={translationText}
            placeholder="Text to translate."
            onChange={e => setTranslationText(e.target.value)} />
          <div style={{ userSelect: 'all', margin: 15, marginTop: 0 }}>
            { translationResult || '-' }
          </div>
        </div> */}


        { /* getUsername() === 'tienoo' &&
        <div className="addpage-btn">
          <div className="action-btn hover-opacity" style={{ paddingBottom: '10px' }} onClick={() => newPageName && addPage(setSite, site, newPageName, setNewPageName, languages)}>
            Add Page
          </div>
          <input
            style={{ width: '95%' }}
            value={newPageName}
            placeholder="Name"
            onChange={e => setNewPageName(e.target.value)} />
        </div>
        <div className="navigator">
          <input
            type="text"
            style={{ width: '95%', marginBottom: '20px' }}
            value={propertyFilter}
            placeholder="Search pages."
            onChange={e => setPropertyFilter(e.target.value)} />
          { map(
              page => getUsername() === 'tienoo' || includes(page, getLeafs(site)) ? (
                <div key={page} style={{ wordWrap: 'break-word' }} onClick={() => setPropertyFilter(page)}>
                  {page}
                </div>
              ) : <div />,
              keys(site).sort()
          ) }
        </div>
        <div style={{ height: 100 }} /> */ }

        <div className="latest-audio-container">
          <div className="latest-audio-title">Latest recording:</div>
          <audio id="player" controls />
        </div>

      </div>
      <div className="editor-container">
        {
          map(
            page => (
              <PageEditor
                key={page}
                setSite={setSite}
                site={site}
                page={page}
                languages={languages}
                textToSpeechLanguage={textToSpeechLanguage}
                textToSpeechVoice={textToSpeechVoice}
                translationOrigin={translationOrigin}
                translationTarget={translationTarget}
                setLoading={setLoading}
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                Mp3Recorder={Mp3Recorder} />
            ),
            take(
              15,
              filter(
                pair => includes(propertyFilter, pair[0]),
                toPairs(site)
              )
            )
          )
        }


      {/*   <pre>
          { JSON.stringify(propertyFilter ? site[propertyFilter] : site, null, 4) }
        </pre>  */}

        { deleteLangmodalVisibility &&
        <CustomModal setVisibility={setDeleteLangModalVisibility}>
          <div className="deletepage-container">
            <div className="newlanguage-modal-title">
              Delete a language.
            </div>
            <div className="pagename-title">
              Language
            </div>
            <input
              className="addpage-input"
              value={removeLanguage}
              placeholder="Language to be removed."
            onChange={e => setRemoveLanguage(e.target.value)} />
            <div
              className="hover-opacity delete-language-btn"
              onClick={() => {
                if (removeLanguage && window.confirm("Remove the language?")) {
                  let siteCopy = JSON.parse(JSON.stringify(site))
                  keys(site).forEach(
                    page => {
                      siteCopy[page] = dissoc(removeLanguage, siteCopy[page])
                    }
                  )
                  setSite(siteCopy)
                  setLanguages(
                    reject(equals(removeLanguage), languages)
                  )
                  setRemoveLanguage('')
                  setDeleteLangModalVisibility(false)
                }
              }}>
              Delete
            </div>
          </div>
        </CustomModal> }

        { newLangmodalVisibility &&
        <CustomModal setVisibility={setNewLangModalVisibility}>
          <div className="newlanguage-modal">
            <div className="newlanguage-modal-title">
              Add a new language.
            </div>
            <div className="pagename-title">
              Language
            </div>
            <input
              className="addpage-input"
              value={newLanguage}
              placeholder="Name of the new language."
              onChange={e => setNewLanguage(e.target.value)} />
            <div className="origintarget-title">
              Origin Language
            </div>
            <div className="select-base-lang-desc">
              Selected language version will be copied as a base language for the new language. Also, you can select "Auto-translate" to translate the English version of the site to any (Google-provided) language you wish.
            </div>
            <div className="select-languages-container"> 
              { map(lang =>
                <div
                  className="hover-opacity"
                  onClick={() => setNewPageBaseLanguage(lang)}
                  style={{
                    /* userSelect: 'all', */
                    fontWeight: newPageBaseLanguage === lang ? 'bold' : '400',
                    padding: 2,
                    color: 'rgba(70,10,10,1)'
                  }}
                  key={lang}>
                    {lang}
                </div>,
                [ ...languages, 'Auto-translate' ] 
              ) }
            </div>
            { newPageBaseLanguage === 'Auto-translate' && !creatingNewLanguage && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input type="text" value={autoTranslateLang} onChange={e => setAutoTranslateLang(e.target.value)} placeholder={'Language code (fi)'} />
                <input type="text" value={autoTextToSpeechLang} onChange={e => setAutoTextToSpeechLang(e.target.value)} placeholder={'Text-to-speech language (fi-FI)'} />
                <input type="text" value={autoTextToSpeechVoice} onChange={e => setAutoTextToSpeechVoice(e.target.value)} placeholder={'Text-to-speech voice (fi-FI-Standard-A)'} />
              </div>
            ) }
            { creatingNewLanguage && (
              <div style={{ textAlign: 'center', padding: 20 }}>
                Generating a new language... This usually takes 5 - 8 min.
              </div>
            ) }
            { !creatingNewLanguage &&
            <div
              className="create-language-btn hover-opacity"
              onClick={() => {
                if (newLanguage && newPageBaseLanguage === 'Auto-translate') {
                  setCreatingNewLanguage(true)
                  translateFullLanguageVersion(site, newLanguage, setSite, setLanguages, languages, 'en', autoTranslateLang, autoTextToSpeechLang, autoTextToSpeechVoice, setNewLanguage, setNewLangModalVisibility, setCreatingNewLanguage)
                  return
                }            
                if (newLanguage) {
                  let siteCopy = JSON.parse(JSON.stringify(site))
                  keys(site).forEach(
                    page => {
                      siteCopy[page][newLanguage] = siteCopy[page][newPageBaseLanguage]
                    }
                  )
                  setSite(siteCopy)
                  setLanguages(append(newLanguage, languages))
                  setNewLanguage('')
                  setNewLangModalVisibility(false)
                }
              }}>
              Create Language
            </div> }
          </div>
        </CustomModal> }

        <div 
          className="create-page-btn hover-opacity"
          onClick={() => setNewPageModalVisibility(true)}>
          <IconContext.Provider value={{ className: "add-page-icon hover-opacity" }}>
            <MdAddCircle />
          </IconContext.Provider>
        </div>

        { newPageModalVisibility &&
        <CustomModal setVisibility={setNewPageModalVisibility}>
          <div className="addpage-modal">
            <div className="addpage-title">
              Add a new page.
            </div>
            <div className="pagename-title">
              Title
            </div>
            <input
              className="addpage-input"
              value={newPageName}
              placeholder="Name of the page. Word CITY will be replaced with your city. /CITY/Kategoriat -> /Vantaa/Kategoriat"
              onChange={e => setNewPageName(e.target.value)} />
            <div
              className="hover-opacity addpage-btn"
              onClick={() => {
                if (!newPageName) return null
                addPage(setSite, site, newPageName, setNewPageName, languages) 
                setNewPageModalVisibility(false)
              }}>
              Create page
            </div>
          </div>
        </CustomModal> }

        <div className={"page-actions-container"}>
          <div
            className="hover-opacity navigator-header"
            onClick={() => setShowPageActions(!showPageActions)}>
            Navigator
            <IconContext.Provider value={{ className: "small-icon hover-opacity light-dark show-hide-pages-icon" }}>
              <IoMdArrowRoundDown />
            </IconContext.Provider>
          </div>
          <div className={"Page-editor-content" + (showPageActions ? '' : ' hide')}>
            {/* <div className="addpage-btn">
              <div className="action-btn hover-opacity" style={{ paddingBottom: '10px' }} onClick={() => newPageName && addPage(setSite, site, newPageName, setNewPageName, languages)}>
                <IconContext.Provider value={{ className: "small-icon hover-opacity green" }}>
                  <MdAddCircleOutline />
                </IconContext.Provider>
                New Page
              </div>
              <input
                style={{ width: '95%' }}
                value={newPageName}
                placeholder="Name of the new page."
                onChange={e => setNewPageName(e.target.value)} />
            </div> */}

            <div className="page-tools">
              <input
                type="text"
                className="search-input"
                value={propertyFilter}
                place
                placeholder="Search pages..."
                onChange={e => setPropertyFilter(e.target.value)} />
              <div className="pages-list-2">
                { mapIndexed(
                    (page, index) => includes(propertyFilter, page) ? (
                      <div
                        key={page}
                        className={'hover-opacity' + ' ' + ' list-item-' + index % 2}
                        style={{ wordWrap: 'break-word' }}
                        onClick={() => setPropertyFilter(page)}>
                        {page}
                      </div>
                    ) : <div />,
                    keys(site).sort()
                ) }
              </div>
            </div>

            <div className="navigator">
              {/* <div className="navigator-title">
                Pages as a hieracy.
              </div> */}
              <div className="pages-folders">
                <FolderNavigation
                  tree={makeSubstringTree(site)}
                  setSearchFilter={setPropertyFilter} />
              </div>
            </div>

            {/* <div className="addpage-btn">
              <div className="action-btn hover-opacity" style={{ paddingBottom: '10px' }} onClick={() => newPageName && addPage(setSite, site, newPageName, setNewPageName, languages)}>
                <IconContext.Provider value={{ className: "small-icon hover-opacity green" }}>
                  <MdAddCircleOutline />
                </IconContext.Provider>
                New Page
              </div>
              <input
                style={{ width: '95%' }}
                value={newPageName}
                placeholder="Name"
                onChange={e => setNewPageName(e.target.value)} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
