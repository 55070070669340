var AWS = require("aws-sdk")
var s3 = new AWS.S3({
  region: 'eu-central-1',
  accessKeyId: 'AKIA4TWP6HG3VRGZ3LWV',
  secretAccessKey: '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'
})

const ASSET_HOST_URL = 'https://files.tienoo.fi/'

export const putAsset = (key, value, callback) => s3.putObject(
  {
    Body: value,
    Bucket: 'tienoo-files', 
    Key: key,
    ACL: 'public-read',
    CacheControl: 'no-cache',
  },
  function(err, data) {
    if (err) {
      console.log('_____ERROR: ', err, err.stack)
      alert('Trouble uploading. Try again, if the problem persists contact developer.')
      callback('error')
    } else {
      console.log('_____SUCCESS: ', data, ASSET_HOST_URL + key)
      callback(ASSET_HOST_URL + key)
    }
  }
)



// JIMP stuff below

var Jimp = require('jimp');

const logoWhiteBg_url = ASSET_HOST_URL + 'whiteBase_picQ2AW.jpg'

export const processLogo = (target) => new Promise((resolve, reject) => {
  Jimp.read(target).then(overlay => {
    overlay.resize(320, Jimp.AUTO)
  
    Jimp.read(logoWhiteBg_url).then(image => {
      image
        .resize(400, Jimp.AUTO)
        .composite(overlay, 40, (266 - overlay.bitmap.height) / 2)
        .quality(70)
  
      image.getBuffer(
        image.getMIME(),
        (error, buffer) => {
          resolve(new Blob( [ buffer ], { type: image.getMIME() } ))
        }
      )
    })
    .catch(err => {
      console.log('Error:', err)
      alert('Something happened. Try again.')
      resolve(null)
    });
  })
})


export const reduceQuality = (item) => new Promise((resolve, reject) => {
  Jimp.read(item)
    .then(img => {
      img
        .resize(400, Jimp.AUTO)
        .quality(85)


      img.getBuffer(
        img.getMIME(),
          (error, buffer) => {
            resolve(new Blob( [ buffer ], { type: img.getMIME() } ))
          }
        )
          
        })
        .catch(err => {
          console.log('Error:', err)
          alert('Something happened. Try again.')
          resolve(null)
        });
})