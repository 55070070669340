import React from 'react'
import './styles.css'

export default ({ children, setVisibility }) => {

  return (
    <div
      className="CustomModal"
      onClick={(e) => setVisibility && setVisibility(false)}>
      <div
        className="white-bg"
        onClick={(e) => e.stopPropagation()}>
        { children }
      </div>
    </div>
  )
}
